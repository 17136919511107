import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import {
  Input,
  Select,
  MaskedInput,
  RadioGroup,
  SocialSecurityInput,
  Textarea,
} from 'components'
import {
  RELATIONSHIP_OPTIONS,
  BOOLEAN_OPTIONS,
  RACE_OPTIONS,
  ETHNICITY_OPTIONS,
  GENDER_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  BOOLEAN_WITH_UNCERTAIN_OPTIONS,
  BOOLEAN_STRING_OPTIONS,
} from 'config'

const propTypes = {
  index: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  currentSsnValue: PropTypes.string.isRequired,
  currentMaritalStatusValue: PropTypes.string.isRequired,
  currentEligibleCitizenValue: PropTypes.string.isRequired,
  currentLivedInOtherStatesValue: PropTypes.string.isRequired,
}

const defaultProps = {}

function HouseholdMembersFormFields({
  index,
  setFieldValue,
  currentSsnValue,
  currentMaritalStatusValue,
  currentEligibleCitizenValue,
  currentLivedInOtherStatesValue,
}) {
  const updateSsnValue = useCallback(
    (ssnValue) => {
      setFieldValue(`householdMembers[${index}].ssn`, ssnValue)
    },
    [index, setFieldValue]
  )
  const getMaritalStatusDetails =
    currentMaritalStatusValue &&
    !['Separated', 'Estranged', 'Single'].includes(currentMaritalStatusValue)
  const getEligibleCitizenDetails =
    currentEligibleCitizenValue && currentEligibleCitizenValue !== 'Yes'
  const getLivedInOtherStatesDetails =
    currentLivedInOtherStatesValue && currentLivedInOtherStatesValue !== 'No'
  const getSeparatedAndEstrangedExtraQuestions =
    currentMaritalStatusValue &&
    ['Separated', 'Estranged'].includes(currentMaritalStatusValue)

  return (
    <>
      <div className="row">
        <div className="col-6">
          <Input
            name={`householdMembers[${index}].firstName`}
            label="First Name"
            required
          />
        </div>
        <div className="col-6">
          <Input
            name={`householdMembers[${index}].lastName`}
            label="Last Name"
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Select
            name={`householdMembers[${index}].relationship`}
            label="Relationship"
            placeholder="Select"
            options={RELATIONSHIP_OPTIONS}
            required
          />
        </div>
        <div className="col-6">
          <MaskedInput
            name={`householdMembers[${index}].dateOfBirth`}
            label="Date of Birth"
            placeholder="MM/DD/YYYY"
            maskOptions={{
              date: true,
              datePattern: ['m', 'd', 'Y'],
            }}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <RadioGroup
            name={`householdMembers[${index}].student`}
            label="Is This Person a Student?"
            options={BOOLEAN_OPTIONS}
            required
          />
          <p>
            <small>
              Indicate “Yes” if this person was a student at any point this
              year.
            </small>
          </p>
        </div>
        <div className="col-6">
          <RadioGroup
            name={`householdMembers[${index}].disabled`}
            label="Does This Person Have a Disability?"
            options={BOOLEAN_OPTIONS}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <SocialSecurityInput
            name={`householdMembers[${index}].ssn`}
            value={currentSsnValue}
            updateValue={updateSsnValue}
          />
          <p>
            <small>Enter 999-99-9999 if none</small>
          </p>
        </div>
        <div className="col-6">
          <RadioGroup
            name={`householdMembers[${index}].income`}
            label="Does This Person Have Any Income?"
            options={BOOLEAN_OPTIONS}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Select
            name={`householdMembers[${index}].race`}
            label="Race"
            placeholder="Select"
            options={RACE_OPTIONS}
            required
          />
        </div>
        <div className="col-6">
          <Select
            name={`householdMembers[${index}].ethnicity`}
            label="Ethnicity"
            placeholder="Select"
            options={ETHNICITY_OPTIONS}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Select
            name={`householdMembers[${index}].maritalStatus`}
            label="Marital Status"
            placeholder="Select"
            options={MARITAL_STATUS_OPTIONS}
            required
          />
          {getMaritalStatusDetails && (
            <div className="">
              <Textarea
                name={`householdMembers[${index}].maritalStatusDetails`}
                label={'Name of Spouse and Other Details'}
                required={getMaritalStatusDetails}
                className="additional-details"
              />
            </div>
          )}
        </div>
        <div className="col-6">
          <Select
            name={`householdMembers[${index}].gender`}
            label="Gender"
            placeholder="Select"
            options={GENDER_OPTIONS}
            required
          />
        </div>
      </div>
      {getSeparatedAndEstrangedExtraQuestions && (
        <>
          <div className="row">
            <div className="col-6">
              <MaskedInput
                name={`householdMembers[${index}].separationDate`}
                label="Indicate date of separation or estrangement"
                placeholder="MM/DD/YYYY"
                maskOptions={{
                  date: true,
                  datePattern: ['m', 'd', 'Y'],
                }}
                required
              />
            </div>
            <div className="col-6">
              <RadioGroup
                name={`householdMembers[${index}].isSeparatedSpousePartOfHousehold`}
                label="Is the spouse currently part of the household?"
                options={BOOLEAN_OPTIONS}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <RadioGroup
                name={`householdMembers[${index}].isEngagedInLegalSeparationAction`}
                label="Is this household member currently engaged in the process of legal separation or divorce?"
                options={BOOLEAN_OPTIONS}
                required
              />
            </div>
            <div className="col-6">
              <RadioGroup
                name={`householdMembers[${index}].understandHalfOfJointAssets`}
                label="Do you understand that 50% of any joint assets held with your spouse will counted as part of your assets/income?"
                options={BOOLEAN_OPTIONS}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <RadioGroup
                name={`householdMembers[${index}].understandRequiredToReportChanges`}
                label="Do you understand that you are required to report any changes to household composition, income, assets, or marital status?"
                options={BOOLEAN_OPTIONS}
                required
              />
            </div>
            <div className="col-6">
              <RadioGroup
                name={`householdMembers[${index}].understandNewMembersAreToBReported`}
                label="Do you understand that adding any new member to your household, including your current spouse, requires prior approval from management?"
                options={BOOLEAN_OPTIONS}
                required
              />
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-6">
          <RadioGroup
            name={`householdMembers[${index}].eligibleCitizen`}
            label="Is This Person an Eligible Citizen?"
            options={BOOLEAN_WITH_UNCERTAIN_OPTIONS}
            required
          />
          <p>
            <small>Citizenship may not effect eligibility</small>
          </p>
          {getEligibleCitizenDetails && (
            <div className="">
              <Textarea
                name={`householdMembers[${index}].eligibleCitizenDetails`}
                label={
                  'Nationality - Country to which you owe legal allegiance - may or may not be country of birth'
                }
                required={getEligibleCitizenDetails}
                className="additional-details"
              />
            </div>
          )}
        </div>
        <div className="col-6">
          <RadioGroup
            name={`householdMembers[${index}].livedInOtherStates`}
            label="Has this member ever lived in any other state?"
            options={BOOLEAN_STRING_OPTIONS}
            required
          />
          {getLivedInOtherStatesDetails && (
            <div className="">
              <Textarea
                name={`householdMembers[${index}].livedInOtherStatesDetails`}
                label={'Details'}
                required={getLivedInOtherStatesDetails}
                className="additional-details"
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

HouseholdMembersFormFields.propTypes = exact(propTypes)
HouseholdMembersFormFields.defaultProps = defaultProps

export default React.memo(HouseholdMembersFormFields)
