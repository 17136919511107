import React from 'react'
import PropTypes from 'prop-types'
import { MaskedInput } from 'components'

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

const defaultProps = {
  label: 'Amount',
}

function CurrencyInput({ name, label, ...rest }) {
  return (
    <MaskedInput
      name={name}
      label={label}
      maskOptions={{
        numeral: true,
        numeralPositiveOnly: true,
        prefix: '$',
        numeralDecimalScale: 2,
      }}
      {...rest}
    />
  )
}

CurrencyInput.propTypes = propTypes
CurrencyInput.defaultProps = defaultProps

export default React.memo(CurrencyInput)
